import { Box, Button, MenuItem, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CotroliaFormControl from "../../../components/core/input/CotroliaFormControl";
import CotroliaInputLabel from "../../../components/core/input/CotroliaInputLabel";
import CotroliaSelect from "../../../components/core/input/CotroliaSelect";
import { useAuth } from "../../auth/hooks/useAuth";
import { Contact } from "../types/userTypes";
import _ from "lodash";

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    alignItems: "center",
  },
  or: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    opacity: 0.64,
  },
  select: {
    flexGrow: 1,
    width: "100%",
  },
  spacer: { margin: `0 ${theme.spacing(2)}px` },
  btn: { margin: ` 0 ${theme.spacing(2)}px` },
}));

const SelectOrAddContact: React.FC<{
  required?: boolean;
  selected?: string;
  onSelect: (contactId: string) => void;
  onClick: () => void;
  excludeList?: Contact[];
  withoutAdd?: boolean;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.thirdparty) {
      if (user.thirdparty.contactList) {
        const newContacts: Contact[] = [];

        for (let i = 0; i < user.thirdparty.contactList.length; i++) {
          const elem = user.thirdparty.contactList[i];

          const contactFormated = {
            id: elem.id,
            email: elem.email,
            firstname: elem.firstname,
            lastname: elem.lastname,
            phone: elem.phone,
          };

          newContacts.push(contactFormated);
        }
        setContacts(_.uniqBy(newContacts, (c) => c.id));
      }
    }
  }, [props.selected, user]);

  return (
    <Box className={classes.container}>
      <Box className={classes.select}>
        <CotroliaFormControl
          variant="filled"
          required={props.required ? true : false}
        >
          <CotroliaInputLabel>{t("SelectMember")}</CotroliaInputLabel>
          <CotroliaSelect
            value={props.selected ? props.selected : ""}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              props.onSelect(e.target.value);
            }}
          >
            {contacts &&
              contacts.map((elem) => {
                if (
                  !props.excludeList ||
                  !props.excludeList.some((el) => el.id === elem.id)
                ) {
                  return (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.lastname} {elem.firstname}
                    </MenuItem>
                  );
                }
                return null;
              })}
          </CotroliaSelect>
        </CotroliaFormControl>
      </Box>
      {!props.withoutAdd && (
        <>
          <Box className={classes.spacer}>
            <Typography variant="button" className={classes.or}>
              {t("Or")}
            </Typography>
          </Box>
          <Box className={classes.btn} onClick={props.onClick}>
            <Button>{t("AddContact")}</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SelectOrAddContact;
