import React, { useState, useEffect } from "react";

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import LocalShipping from "@material-ui/icons/LocalShipping";
import AccessTime from "@material-ui/icons/AccessTime";

import moment from "moment";

import { useTranslation } from "react-i18next";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaTextField from "../../../../components/core/input/CotroliaTextField";
import CotroliaInputSkeleton from "../../../../components/core/skeleton/CotroliaInputSkeleton";
import CotroliaDatePicker from "../../../../components/core/input/CotroliaDatePicker";
import CotroliaSelect from "../../../../components/core/input/CotroliaSelect";
import AppConstant from "../../../../utils/appConstant";
import CotroliaInputLabel from "../../../../components/core/input/CotroliaInputLabel";

const useStyle = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& .MuiSkeleton-root, .MuiFormControl-root": {
      flexBasis: "33%",
      [theme.breakpoints.down("md")]: {
        flexBasis: "49%",
      },
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&:after": {
        content: "''",
        flexBasis: "33%",
      },
    },
  },
  trackingContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: "10px",
    padding: theme.spacing(2),
  },
}));

/**
 * Content of the shipping section
 *
 * @param   {Object}  intervention     Intervention sent by the api
 * @returns                            Component content
 */
const InterventionDetailShipping = ({ intervention }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [interventionshipping, setInterventionShipping] = useState(null);

  useEffect(() => {
    if (intervention && intervention.shipping) {
      setInterventionShipping(intervention.shipping);
    }
  }, [intervention]);

  return (
    <Box>
      <Box className={classes.descriptionContainer}>
        {/* Shipping method */}
        {interventionshipping ? (
          <CotroliaFormControl variant="filled">
            <CotroliaInputLabel id="method-label">
              {t("TransportShippingMethod")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="method"
              labelId="method-label"
              value={interventionshipping.method}
              disabled
            >
              <MenuItem value={AppConstant.shippingMethodPicking}>
                {t("PickingMethodDetail")}
              </MenuItem>
              <MenuItem value={AppConstant.shippingMethodPersonal}>
                {t("PersonalMethod")}
              </MenuItem>
              <MenuItem value={AppConstant.shippingMethodDesk}>
                {t("DeskMethod")}
              </MenuItem>
            </CotroliaSelect>
          </CotroliaFormControl>
        ) : (
          <CotroliaFormControl>
            <CotroliaTextField label={t("TransportShippingMethod")} disabled />
          </CotroliaFormControl>
        )}

        {interventionshipping &&
          parseInt(interventionshipping.method) ===
            AppConstant.shippingMethodPicking && (
            <>
              {/* Accepted or not */}
              {intervention ? (
                <CotroliaFormControl>
                  <CotroliaTextField
                    label={t("ShippingStatus")}
                    value={
                      intervention.shipping && intervention.shipping.number
                        ? t("ShippingAccepted")
                        : t("ShippingRefused")
                    }
                    disabled
                  />
                </CotroliaFormControl>
              ) : (
                <CotroliaInputSkeleton />
              )}

              {/* Pickup date */}
              {intervention ? (
                <CotroliaFormControl>
                  <CotroliaDatePicker
                    label={t("ShippingDate")}
                    value={
                      interventionshipping && interventionshipping.date
                        ? interventionshipping.date
                        : ""
                    }
                    disablePast
                    invalidDateMessage=""
                    disabled
                    error={false}
                  />
                </CotroliaFormControl>
              ) : (
                <CotroliaInputSkeleton />
              )}

              {/* Parcel number */}
              {intervention ? (
                <CotroliaFormControl>
                  <CotroliaTextField
                    label={t("ParcelNumber")}
                    value={
                      interventionshipping && interventionshipping.number
                        ? interventionshipping.number
                        : ""
                    }
                    disabled
                  />
                </CotroliaFormControl>
              ) : (
                <CotroliaInputSkeleton />
              )}

              {/* Pickup carrier */}
              {intervention ? (
                <CotroliaFormControl>
                  <CotroliaTextField
                    label={t("Carrier")}
                    value={
                      interventionshipping && interventionshipping.carrier
                        ? interventionshipping.carrier
                        : ""
                    }
                    disabled
                  />
                </CotroliaFormControl>
              ) : (
                <CotroliaInputSkeleton />
              )}
            </>
          )}
      </Box>

      {interventionshipping &&
        parseInt(interventionshipping.method) ===
          AppConstant.shippingMethodPicking && (
          <>
            {/* Tracking*/}
            {intervention ? (
              <Box className={classes.trackingContainer}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <LocalShipping style={{ marginRight: 5 }} />
                  <Typography variant="body2" color="primary">
                    {t("ParcelTracking")}
                  </Typography>
                </Box>
                {interventionshipping?.track?.events ? (
                  interventionshipping.track.events.map((item, key) => (
                    <Card key={key} style={{ margin: 10 }}>
                      <CardContent>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{
                              padding: 5,
                              marginRight: 10,
                              flexBasis: "15%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <AccessTime style={{ marginBottom: 5 }} />
                            <Typography variant="caption">
                              {moment.unix(item.date).format("lll")}
                            </Typography>
                          </Box>
                          <Box style={{ flexGrow: 1 }}>
                            <Typography variant="body1">
                              {item.label}
                            </Typography>
                            <Typography variant="body2">
                              {item.office}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <CotroliaInputSkeleton />
            )}
          </>
        )}
    </Box>
  );
};

export default InterventionDetailShipping;
