import React, { useEffect, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline,
  EmojiTransportation,
  FilterList,
  MoreHoriz,
  Warning,
} from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Garage } from "../features/garages/types/GarageTypes";
import { useGarages } from "../features/garages/hooks/useGarages";
import NoGarage from "../features/garages/components/NoGarage";
import GaragesFilters from "../features/garages/components/GaragesFilters";
import Datagrid from "../components/core/Datagrid";
import { useAuth } from "../features/auth/hooks/useAuth";
import moment from "moment";
import {
  CompanyFilter,
  CompanySort,
} from "../features/companies/types/CompaniesTypes";
import Link from "@material-ui/core/Link";
import { useNavigate } from "react-router-dom";
import { useCompanies } from "../features/companies/hooks/useCompanies";
import { AlertDialogModal } from "../components/core/AlertDialogModal";
import { AlertCustomOptions, useAlert } from "react-alert";
import NoFilterResult from "../components/core/NoFilterResult";

const Garages: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const device = useDevice();
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<CompanyFilter>({});
  const someFilters = Object.values(filters).some((value) => !!value);
  const [sort, setSort] = useState<CompanySort>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [garages, setGarages] = useState<Garage[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchGarages } = useGarages();

  useEffect(() => {
    setLoading(true);
    searchGarages(rowsPerPage, page, filters, sort)
      .then((res) => {
        setGarages(res.results);
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchGarages, sort]);

  const refreshPage = () => {
    setLoading(true);
    searchGarages(rowsPerPage, page, filters, sort)
      .then((res) => {
        setGarages(res.results);
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header
        focus={false}
        title={t("MenuGarages")}
        titleIcon={<EmojiTransportation />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
              {user?.permissions.createNetworkGarage && (
                <Link
                  onClick={() => navigate("/garage/new")}
                  style={{ textDecoration: "none" }}
                >
                  <CircleButton color="primary" variant="contained">
                    <AddCircleOutline />
                  </CircleButton>
                </Link>
              )}
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
              {user?.permissions.createNetworkGarage && (
                <Link
                  onClick={() => navigate("/garage/new")}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ margin: "0px 16px" }}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleOutline />}
                  >
                    {t("NewGarage")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {(total > 0 || someFilters) && (
          <Collapse in={displayFilter}>
            <GaragesFilters applyFilters={setFilters} />
          </Collapse>
        )}
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoGarage />
              </Box>
            )}
          </>
        ) : (
          <Datagrid
            rows={garages}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            enablePageSelector
            onChangePage={(p) => setPage(p)}
            columnsDef={[
              {
                header: t("GaragesHeaderName"),
                format: (value) => value.name,
              },
              {
                header: t("GaragesHeaderClientCode"),
                format: (value) => value.customerCode,
                colWidth: "auto",
              },
              {
                header: t("GaragesHeaderShopCode"),
                format: (value) => value.shopCode,
                colWidth: "auto",
              },
              {
                header: t("GaragesHeaderDateCreation"),
                format: (value) =>
                  value.datec ? moment.unix(value.datec).format("L") : "-",
                colWidth: "auto",
              },
              {
                header: t("GaragesHeaderEmail"),
                format: (value) => value.email,
              },
              {
                header: t("GaragesHeaderStatus"),
                format: (value) => (
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        value.isLocked === "1"
                          ? theme.palette.warning.main
                          : theme.palette.success.main,
                    }}
                  >
                    {value.isLocked === "1"
                      ? t("GaragesHeaderStatusBlocked")
                      : t("GaragesHeaderStatusActive")}
                  </Typography>
                ),
                colWidth: "auto",
              },
              {
                header: "",
                format: (value) => (
                  <ColActions
                    value={value}
                    onLock={() => refreshPage()}
                    onUnlock={() => refreshPage()}
                  />
                ),
                colWidth: "auto",
              },
            ]}
            sort={sort}
            lastColSticky
            onSort={(sort) => setSort(sort)}
            loading={isLoading}
          />
        )}
      </Content>
    </>
  );
};

const ColActions: React.FC<{
  value: Garage;
  onLock: () => void;
  onUnlock: () => void;
}> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const { user, askResetPassword } = useAuth();
  const { lockCompany, unlockCompany } = useCompanies();
  const classes = makeStyles({
    root: {
      padding: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 700,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    action: {
      justifyContent: "flex-start",
      color: theme.palette.primary.main,
      textTransform: "initial",
      "& p": {
        fontWeight: 500,
      },
    },
  })();
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | undefined
  >();
  const [confirmLockOpen, setConfirmLockOpen] = useState<boolean>(false);
  const [confirmUnlockOpen, setConfirmUnlockOpen] = useState<boolean>(false);
  const [sendResetPasswordOpen, setsendResetPasswordOpen] =
    useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.root}>
          <Typography variant="caption" className={classes.title}>
            {t("GaragesActionsMenuTitle")}
          </Typography>
          <Button
            className={classes.action}
            size="small"
            onClick={() => navigate(`/garage/${props.value.id}/edit`)}
          >
            <Typography variant="body2">{t("GarageActionEdit")}</Typography>
          </Button>
          {props.value.isLocked === "0" && (
            <Button
              className={classes.action}
              size="small"
              onClick={() => {
                setConfirmLockOpen(true);
                handleClose();
              }}
            >
              <Typography variant="body2">{t("GarageActionLock")}</Typography>
            </Button>
          )}
          {props.value.isLocked === "1" && (
            <Button
              className={classes.action}
              size="small"
              onClick={() => {
                setConfirmUnlockOpen(true);
                handleClose();
              }}
            >
              <Typography variant="body2">{t("GarageActionUnlock")}</Typography>
            </Button>
          )}
          {user?.permissions.createNetworkGarage && (
            <Button
              className={classes.action}
              size="small"
              onClick={() => {
                setsendResetPasswordOpen(true);
                handleClose();
              }}
            >
              <Typography variant="body2">
                {t("GarageActionResetPassword")}
              </Typography>
            </Button>
          )}
        </div>
      </Popover>

      {confirmLockOpen && (
        <AlertDialogModal
          open={confirmLockOpen}
          icon={<Warning />}
          title={t("GarageConfirmLockTitle")}
          content={t("GarageConfirmLockContent")}
          confirmText={t("GarageConfirmLockOk")}
          cancelText={t("Cancel")}
          onClose={() => setConfirmLockOpen(false)}
          onConfirm={() => {
            setConfirmLockOpen(false);
            lockCompany(props.value.id!).then(() => {
              props.onLock();
              const myAlert = alert.show("", {
                title: t("SuccessLockGarage"),
                close: () => {
                  alert.remove(myAlert);
                },
                type: "success",
              } as unknown as AlertCustomOptions);
            });
          }}
        />
      )}

      {confirmUnlockOpen && (
        <AlertDialogModal
          open={confirmUnlockOpen}
          icon={<Warning />}
          title={t("GarageConfirmUnlockTitle")}
          content={t("GarageConfirmUnlockContent")}
          confirmText={t("GarageConfirmUnlockOk")}
          cancelText={t("Cancel")}
          onClose={() => setConfirmUnlockOpen(false)}
          onConfirm={() => {
            setConfirmUnlockOpen(false);
            unlockCompany(props.value.id!).then(() => {
              props.onUnlock();
              const myAlert = alert.show("", {
                title: t("SuccessUnlockGarage"),
                close: () => {
                  alert.remove(myAlert);
                },
                type: "success",
              } as unknown as AlertCustomOptions);
            });
          }}
        />
      )}

      {sendResetPasswordOpen && (
        <AlertDialogModal
          open={sendResetPasswordOpen}
          icon={<Warning />}
          title={t("GarageConfirmResetPasswordTitle")}
          content={t("GarageConfirmResetPasswordContent")}
          confirmText={t("GarageConfirmResetPasswordOk")}
          cancelText={t("Cancel")}
          onClose={() => setsendResetPasswordOpen(false)}
          onConfirm={() => {
            setsendResetPasswordOpen(false);
            askResetPassword(props.value.email!).then(() => {
              const myAlert = alert.show("", {
                title: t("SuccessResetPasswordLinkSend"),
                close: () => {
                  alert.remove(myAlert);
                },
                type: "success",
              } as unknown as AlertCustomOptions);
            });
          }}
        />
      )}
    </>
  );
};

export default Garages;
