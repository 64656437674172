import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import {
  Company,
  CompanyFilter,
  CompanySort,
  CompanyEdit,
} from "../types/CompaniesTypes";

export const useCompanies = (): {
  searchCompanies: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Company[]; total: string }>;
  getCompany: (id: string) => Promise<Company | undefined>;
  createCompany: (company: CompanyEdit) => Promise<void>;
  updateCompany: (company: CompanyEdit) => Promise<void>;
  lockCompany: (childCompanyId: string) => Promise<void>;
  unlockCompany: (childCompanyId: string) => Promise<void>;
} => {
  const { call } = useApi();

  const searchCompanies = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Company[]; total: string }> =>
      call("/supercotrolia/companies/search", {
        data: {
          limit,
          page,
          search,
          order: sort,
        },
      }),
    [call],
  );

  const createCompany = (company: CompanyEdit): Promise<void> => {
    return call("/supercotrolia/companies", { data: company });
  };

  const lockCompany = (childCompanyId: string): Promise<void> => {
    return call(`/supercotrolia/companies/${childCompanyId}/lock`, {
      method: "POST",
    });
  };

  const unlockCompany = (childCompanyId: string): Promise<void> => {
    return call(`/supercotrolia/companies/${childCompanyId}/unlock`, {
      method: "POST",
    });
  };

  const updateCompany = (company: CompanyEdit): Promise<void> => {
    return call(`/supercotrolia/companies/${company.id}`, {
      data: company,
      method: "PUT",
    });
  };

  const getCompany = useCallback(
    (id: string): Promise<Company | undefined> => {
      return call("/supercotrolia/companies/" + id);
    },
    [call],
  );

  return {
    searchCompanies,
    getCompany,
    createCompany,
    updateCompany,
    lockCompany,
    unlockCompany,
  };
};
