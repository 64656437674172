import { useCallback } from "react";
import { Distributor, DistributorEdit } from "../types/DistributorsTypes";
import { useCompanies } from "../../companies/hooks/useCompanies";
import {
  CompanyFilter,
  CompanySort,
} from "../../companies/types/CompaniesTypes";

export const useDistributors = (): {
  searchDistributors: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Distributor[]; total: string }>;
  createDistributor: (distributor: DistributorEdit) => Promise<void>;
  updateDistributor: (distributor: DistributorEdit) => Promise<void>;
  getDistributor: (id: string) => Promise<Distributor | undefined>;
} => {
  const { searchCompanies, createCompany, updateCompany, getCompany } =
    useCompanies();

  const searchDistributors = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Distributor[]; total: string }> =>
      searchCompanies(limit, page, search, sort),
    [searchCompanies],
  );

  const createDistributor = (distributor: DistributorEdit): Promise<void> => {
    return createCompany(distributor);
  };

  const updateDistributor = (distributor: DistributorEdit): Promise<void> => {
    return updateCompany(distributor);
  };

  const getDistributor = useCallback(
    (id: string): Promise<Distributor | undefined> => {
      return getCompany(id);
    },
    [getCompany],
  );

  return {
    searchDistributors,
    createDistributor,
    updateDistributor,
    getDistributor,
  };
};
