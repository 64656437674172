import { useCallback } from "react";
import { Garage, GarageEdit } from "../types/GarageTypes";
import { useCompanies } from "../../companies/hooks/useCompanies";
import {
  CompanyFilter,
  CompanySort,
} from "../../companies/types/CompaniesTypes";

export const useGarages = (): {
  searchGarages: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Garage[]; total: string }>;
  createGarage: (garage: GarageEdit) => Promise<void>;
  updateGarage: (garage: GarageEdit) => Promise<void>;
  getGarage: (id: string) => Promise<Garage | undefined>;
} => {
  const { searchCompanies, createCompany, updateCompany, getCompany } =
    useCompanies();

  const searchGarages = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Garage[]; total: string }> =>
      searchCompanies(limit, page, search, sort),
    [searchCompanies],
  );

  const createGarage = (garage: GarageEdit): Promise<void> => {
    return createCompany(garage);
  };

  const updateGarage = (garage: GarageEdit): Promise<void> => {
    return updateCompany(garage);
  };

  const getGarage = useCallback(
    (id: string): Promise<Garage | undefined> => {
      return getCompany(id);
    },
    [getCompany],
  );

  return {
    searchGarages,
    createGarage,
    updateGarage,
    getGarage,
  };
};
