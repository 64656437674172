import { useApi } from "../../../hooks/useApi";
import toBase64 from "../../../utils/base64";

export const useContactForm = (): {
  sendEmail: (service: string, content: string, files: File[]) => Promise<void>;
} => {
  const { call } = useApi();

  const sendEmail = async (service: string, content: string, files: File[]) => {
    const formattedFiles = await formatFiles(files);

    return call("/supercotrolia/contact", {
      data: {
        service,
        content: content.replace(/\n/g, "<br />"),
        files: formattedFiles,
      },
    });
  };

  const formatFiles = async (files: File[]) => {
    const newFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filecontent = await toBase64(file);
      newFiles.push({ filename: file.name, filecontent });
    }

    return newFiles;
  };

  return {
    sendEmail,
  };
};
