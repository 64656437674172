import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { useApi } from "../hooks/useApi";
import { NewInterventionConfig } from "../features/interventions/types/NewInterventionConfigTypes";
import {
  CarBrand,
  CarModel,
} from "../features/interventions/types/InterventionTypes";

type Country = {
  label: string;
  code: string;
};

type CountryList = {
  [key: string]: Country;
};

export interface Configuration {
  isUnderMaintenance: boolean;
  themeDeclination: string;
  lightTheme?: PaletteOptions;
  darkTheme?: PaletteOptions;
  availableLangs: { code: string; label: string }[];
  availableShippingCountries: {
    [key: string]: { code: string; label: string };
  };
  declination: string;
  logoLight: string;
  logoLightSmall: string;
  logoDark: string;
  logoDarkSmall: string;
  name: string;
  externalUrlToolsMenu: string;
  countries: CountryList;
  sector: string;
  idMatomoTrack: string;
  matomoUrl: string;
  featuredProductUrl: string;
  featuredProductUsername: string;
  featuredProductUsersecret: string;
  title: string;
  favicon: string;
  externalApiUrl: string | null;
  canRegister: boolean;
  enablePayment: boolean;
  redirectUrlWAYF: string;
  statsMetabaseUrls: string;
}

const defaultConfiguration: Configuration = {
  isUnderMaintenance: false,
  themeDeclination: "",
  availableLangs: [],
  availableShippingCountries: {},
  declination: "",
  logoLight: "",
  logoLightSmall: "",
  logoDark: "",
  logoDarkSmall: "",
  name: "",
  externalUrlToolsMenu: "",
  countries: {},
  sector: "",
  idMatomoTrack: "",
  matomoUrl: "",
  featuredProductUrl: "",
  featuredProductUsername: "",
  featuredProductUsersecret: "",
  title: "",
  favicon: "",
  externalApiUrl: null,
  canRegister: true,
  enablePayment: true,
  redirectUrlWAYF: "",
  statsMetabaseUrls: "",
};

interface ConfigurationContextType extends Configuration {
  loading: boolean;
  interventionConfig: NewInterventionConfig;
  refresh: () => Promise<void>;
  refreshInterConfig: () => Promise<void>;
  getCarBrandFromModel: (carModel: CarModel) => CarBrand | undefined;
}

export const ConfigurationContext =
  React.createContext<ConfigurationContextType>({} as ConfigurationContextType);

let confFetched = false;

const ConfigurationProvider: React.FC = (props) => {
  const { call, setBaseUrl } = useApi();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [configuration, setConfiguration] =
    useState<Configuration>(defaultConfiguration);
  const [interventionConfig, setInterventionConfig] =
    useState<NewInterventionConfig>({} as NewInterventionConfig);

  const applyConfiguration = useCallback(
    (config: Configuration) => {
      if (import.meta.env.PROD && !!config.externalApiUrl) {
        setBaseUrl(config.externalApiUrl);
      }
      document.title = config.title || config.name;
      const faviconEl: HTMLLinkElement | null =
        document.querySelector("#favicon");
      if (faviconEl) {
        faviconEl.href = config.favicon;
      }

      const name = config.title || config.name;

      const manifest = {
        short_name: name,
        name: name,
        icons: [
          {
            src: config.favicon,
            sizes: "any",
            type: "image/png",
          },
          {
            src: "favicon.ico",
            sizes: "144x144 48x48",
            type: "image/png",
          },
        ],
        display: "standalone",
        theme_color: "#000000",
        background_color: "#ffffff",
      };
      const blob = new Blob([JSON.stringify(manifest)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);

      const manifestEL: HTMLLinkElement | null =
        document.querySelector("#manifest");
      if (manifestEL) {
        manifestEL.setAttribute("href", url);
      }
    },
    [setBaseUrl],
  );

  const fetchConfiguration = useCallback(
    () =>
      call("/supercotrolia/configuration?langCode=" + i18n.language)
        .then((config: Configuration) => {
          const newConfig = { ...defaultConfiguration, ...config };
          setConfiguration(newConfig);
          applyConfiguration(newConfig);
        })
        .finally(() => {
          setLoading(false);
        }),
    [applyConfiguration, call, i18n.language],
  );

  const fetchInterventionConfiguration = () =>
    call("/supercotrolia/interventions/configuration").then(
      setInterventionConfig,
    );

  useEffect(() => {
    if (!confFetched) {
      fetchConfiguration();
    }
    confFetched = true;
  }, [fetchConfiguration]);

  const getCarBrandFromModel = (carModel?: CarModel): CarBrand | undefined => {
    if (!carModel) {
      return undefined;
    }
    const entries = Object.entries(interventionConfig.carBrandModel ?? {});
    const brandEntry = entries.find((brand) => {
      const brandModels = Object.keys(brand[1].values);
      return brandModels.includes(carModel.id);
    });
    return brandEntry
      ? { id: brandEntry[0], label: brandEntry[1].label }
      : undefined;
  };

  return (
    <ConfigurationContext.Provider
      value={{
        loading: loading,
        ...configuration,
        interventionConfig,
        refresh: fetchConfiguration,
        refreshInterConfig: fetchInterventionConfiguration,
        getCarBrandFromModel,
      }}
      {...props}
    >
      {props.children}
    </ConfigurationContext.Provider>
  );
};

export { ConfigurationProvider };
