import React from "react";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { ShippingMethod } from "../../../types/NewInterventionFormTypes";
import { Box, Card, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useConfiguration } from "../../../../../hooks/useConfiguration";

const FieldEntityAddress: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { interventionConfig } = useConfiguration();
  const { intervention } = useNewInterventionForm();

  const classes = makeStyles(() => ({
    addressText: {
      "& > p": { margin: 0 },
    },
  }))();

  return (
    <Box>
      {[ShippingMethod.Personal, ShippingMethod.Desk].includes(
        intervention.shipping.method,
      ) && (
        <>
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: theme.spacing(1.5),
              color: theme.palette.primary.main,
            }}
          >
            {intervention.shipping.method === ShippingMethod.Personal &&
              t("EntityAddressTitlePersonal")}
            {intervention.shipping.method === ShippingMethod.Desk &&
              t("EntityAddressTitleDesk")}
          </Typography>
          <Card
            elevation={0}
            style={{
              border: `1px dashed ${theme.palette.grey[300]}`,
              padding: theme.spacing(1.5),
              borderRadius: 10,
            }}
          >
            {intervention.shipping.method === ShippingMethod.Personal && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: interventionConfig.addressSend,
                }}
                className={classes.addressText}
              />
            )}

            {intervention.shipping.method === ShippingMethod.Desk && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: interventionConfig.addressDesk,
                }}
                className={classes.addressText}
              />
            )}
          </Card>
        </>
      )}
    </Box>
  );
};

export default FieldEntityAddress;
