import React from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import { useTranslation } from "react-i18next";
import { PieChartOutlined } from "@material-ui/icons";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useConfiguration } from "../hooks/useConfiguration";

const Statistics: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
      "& iframe": {
        width: "100%",
        minHeight: 600,
      },
    },
  }))();
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const metbaseUrls = configuration.statsMetabaseUrls
    .split(",")
    .map((s) => s.trim().replace("https://", "https://ext."))
    .filter((s) => !!s);

  return (
    <>
      <Header
        focus={false}
        title={t("MenuStatistics")}
        titleIcon={<PieChartOutlined />}
      />
      <Content>
        <div className={classes.root}>
          {metbaseUrls.length === 0 && <NoStatistics />}
          {metbaseUrls.map((url) => {
            return <iframe src={url} frameBorder="0" key={url} />;
          })}
        </div>
      </Content>
    </>
  );
};

export default Statistics;

const NoStatistics = () => {
  const { t } = useTranslation();

  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3),
      margin: theme.spacing(2, 0),
      textAlign: "center",
    },
    margin: {
      margin: theme.spacing(1, 0),
    },
  }))();

  return (
    <Box className={classes.root}>
      <PieChartOutlined fontSize="large" />
      <Typography variant="h5" className={classes.margin}>
        {t("NoStatistics")}
      </Typography>
    </Box>
  );
};
